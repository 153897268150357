import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { VueAxios } from './axios'
import { Modal, notification } from 'ant-design-vue'
import { ACCESS_TOKEN } from "@/store/mutation-types"

console.log('====================================');
console.log(process.env.NODE_ENV,'-----');
console.log(process.env);
console.log('====================================');

let project_Env = process.env.NODE_ENV;

if (project_Env == 'development') {
  // process.env.BASE_URL = '/develop'
} else if (project_Env == 'test') {
  // process.env.BASE_URL = '/gateway'
} else {
  // process.env.BASE_URL = '/gateway'
}

console.log('====================================');
console.log(process.env.BASE_URL);
console.log('====================================');
// 创建 axios 实例
// http://backend.tex.mejust.com
const service = axios.create({
  baseURL: window._CONFIG['domianURL'], // 本地2
  // baseURL: process.env.BASE_URL, // 本地2
  // baseURL: 'https://gig.zfycloud.com',
  timeout: 120000 // 请求超时时间
})

const err = (error) => {
  if (error.response) {
    let data = error.response.data
    const token = Vue.ls.get(ACCESS_TOKEN)
    switch (error.response.status) {
      case 403:
        notification.error({ message: '系统提示', description: '拒绝访问', duration: 4 })
        break
        case 510:
        
          
              store.dispatch('Logout').then(() => {
                Vue.ls.remove(ACCESS_TOKEN)
                this.$router.push('/login')
              })
           
          // update-end- --- author:scott ------ date:20190225 ---- for:Token失效采用弹框模式，不直接跳转----
        
        break
      case 500:
        if (token && data.message == "操作失败，Token失效，请重新登录!") {
          Modal.error({
            title: '登录已过期',
            content: '很抱歉，登录已过期，请重新登录1',
            okText: '重新登录',
            mask: false,
            onOk: () => {
              store.dispatch('Logout').then(() => {
                Vue.ls.remove(ACCESS_TOKEN)
                this.$router.push('/login')
              })
            }
          })
          // update-end- --- author:scott ------ date:20190225 ---- for:Token失效采用弹框模式，不直接跳转----
        }
        break
      case 404:
        notification.error({ message: '系统提示', description: '很抱歉，资源未找到!', duration: 4 })
        break
      case 504:
        notification.error({ message: '系统提示', description: '网络超时' })
        break
      case 401:
        notification.error({ message: '系统提示', description: '未授权，请重新登录', duration: 4 })
        if (token) {
          store.dispatch('Logout').then(() => {
            setTimeout(() => {
              window.location.reload()
            }, 1500)
          })
        }
        break
      default:
        notification.error({
          message: '系统提示',
          description: data.message,
          duration: 4
        })
        break
    }
  }
  return Promise.reject(error)
};

// request interceptor
service.interceptors.request.use(config => {
  const token = Vue.ls.get(ACCESS_TOKEN)
  if (token) {
    config.headers['X-Access-Token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  if (config.method == 'get') {
    if (config.url.indexOf("sys/dict/getDictItems") < 0) {
      config.params = {
        _t: Date.parse(new Date()) / 1000,
        ...config.params
      }
    }
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

// response interceptor
service.interceptors.response.use((response) => {
  if (response.data.code === 500 && response.data.message == "操作失败，Token失效，请重新登录!") {
    Modal.error({
      title: '登录已过期',
      content: '很抱歉，登录已过期，请重新登录',
      okText: '重新登录',
      mask: false,
      onOk: () => {
        store.dispatch('Logout').then(() => {
          Vue.ls.remove(ACCESS_TOKEN)
          window.location.reload()
        })
      }
    })
  }
  if (response.data.code === 510 ) {
    Modal.error({
      title: '登录已过期',
      content: '很抱歉，登录已过期，请重新登录',
      okText: '重新登录',
      mask: false,
      onOk: () => {
        store.dispatch('Logout').then(() => {
          Vue.ls.remove(ACCESS_TOKEN)
          window.location.reload()
        })
      }
    })
  }
  return response.data
}, err)

const installer = {
  vm: {},
  install(Vue, router = {}) {
    Vue.use(VueAxios, router, service)
  }
}

export {
  installer as VueAxios,
  service as axios
}